import React, {useEffect} from 'react';
import {useStaticQuery} from "gatsby";

import './link-policy.scss';
import {
  toggleModal,
  toggleModalContent,
  toggleModalSize,
  toggleModalTitle,
  toggleModalType
} from '../../state/createStore';

import {useDispatch} from 'react-redux';

const LinkPolicy = (props) => {

  const dispatch = useDispatch();

  const privacyContent = useStaticQuery(graphql`
    {
      wpPage(isPrivacyPage: {eq: true}) {
        content
      }
    }
  `).wpPage.content;

  return (
    <a href={props.href} onClick={(e) => openModalPolicy(e, {cardModalContent: privacyContent}, dispatch)}
       className={`link ${props.className}`}>
      {props.text}
    </a>)
};

const openModalPolicy = (e, modalContent, dispatch) => {
  e.stopPropagation();
  e.preventDefault();
  dispatch(toggleModal(true));
  dispatch(toggleModalType('empty'));
  dispatch(toggleModalSize('big'));
  dispatch(toggleModalContent(modalContent));
  dispatch(toggleModalTitle(''));
}

export default LinkPolicy;
