import React, {useState, useEffect} from 'react';
import './header.scss';

import MenuTop from '../menu-top';
import Logo from '../logo';
import Socials from '../socials';
import Link from '../link';

const Header = () => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 30;
      if (isScrolled !== scrolled) {
        setScrolled(!scrolled);
      }
    };

    document.addEventListener('scroll', handleScroll, {passive: true});

    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [scrolled]);


  return (<header className={`header ${scrolled ? 'header--fixed' : ''}`}>
    <div className="header__wrapper">
      <div className="header__logo">
        <Logo/>
      </div>
      <div className="header__menu">
        <div className="container">
          <MenuTop/>
        </div>
      </div>
      <div className="header__socials">
        <Socials className='socials--white'/>
      </div>
      <div className="header__contacts">
        <Link href='mailto:info@itglobal.com' text='info@itglobal.com' className='link--def'/>
      </div>
    </div>
  </header>);
};

export default Header;
