import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import '../scss/app.scss';

import Header from './header';
import TitleBlock from './title-block';
import Portfolio from '../blocks/portfolio';
import Strategy from '../blocks/strategy';
import OurTeam from '../blocks/our-team';
import ForWhom from '../blocks/for-whom/for-whom';
import ContactsSection from '../blocks/contacts-section/contacts-section';
import Footer from './footer';
import Modal from './modal';

import appleTouchIcon from '../images/favicons/apple-touch-icon.png';

const Layout = () => {

  return (<>
    <Helmet>
      <title>ITGV</title>
      <link rel="apple-touch-icon" href={appleTouchIcon} />
      <link rel="preload" href="/fonts.css" as="style" />
      <link rel="preload" href="/fonts/Gilroy-Bold.woff2" as="font" crossOrigin="anonymous" />
      <link rel="preload" href="/fonts/Gilroy-Medium.woff2" as="font" crossOrigin="anonymous" />
      <link rel="preload" href="/fonts/Gilroy-Regular.woff2" as="font" crossOrigin="anonymous" />
      <link rel="preload" href="/fonts/TTFirsNeue-Medium.woff2" as="font" crossOrigin="anonymous" />
      <link rel="preload" href="/fonts/TTFirsNeue-DemiBold.woff2" as="font" crossOrigin="anonymous" />
    </Helmet>
    <Header/>
    <main>
      <TitleBlock/>
      <Portfolio/>
      <Strategy/>
      <OurTeam/>
      <ForWhom/>
      <ContactsSection/>
    </main>
    <Footer/>
    <Modal/>
  </>)
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
