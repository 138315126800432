import React from 'react';
import './row.scss';

const Row = (props) => (
  <div className={`row ${props.className ? props.className : ''}`}>
    {props.children}
  </div>
);

export default Row;
