import React, {useEffect} from 'react';
import './link.scss';

const Link = (props) => {
  let icon = props.icon ? <span className='link__icon' dangerouslySetInnerHTML={{__html: props.icon}}></span> : '';
  return (
    <a onClick={props.smooth ? scrollToElem : null} className={`link ${props.className}`} href={props.href} target={props.target}>
      {props.text}
      {icon}
    </a>
  )
};


const scrollToElem = (e) => {
  e.preventDefault();
  let id = e.target.getAttribute('href');
  let section = document.querySelector(id);

  window.scrollTo({
    top: section.offsetTop,
    behavior: "smooth"
  });
}

export default Link;
