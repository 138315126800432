import React from 'react';
import {useStaticQuery} from "gatsby";

import Row from '../../components/row';
import Col from '../../components/col';
import ForWhomItem from '../../components/for-whom-item';
import Section from '../../components/section';

const ForWhom = () => {
  const data = useStaticQuery(graphql`
    {
      allWpPartner {
        nodes {
          acfPartners {
            icon {
              localFile {
                publicURL
              }
            }
          }
          content
          title
          id
        }
      }
    }
  `);

  return (
    <Section id="for-whom" className='section--bg section--blue'>
      <div className="container">
        <Row>
          {data.allWpPartner.nodes.map(item =>
            <Col countCol='2' key={item.id}>
              <ForWhomItem client={item}/>
            </Col>)
          }
        </Row>
      </div>
    </Section>
  );
};

export default ForWhom;
