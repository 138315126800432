import React, {useEffect} from 'react';
import './dots.scss';
import Dot from '../dot';

const Dots = (props) => {
  const dots = props.items.map((item, i) => <Dot key={i} isActive={props.numberActiveItem === i}/>);

  return (<div className='dots'>
    {dots}
  </div>)
}

export default Dots;
