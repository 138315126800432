import React from 'react';
import Dots from '../dots';
import './slider.scss';
import Siema from 'siema';

class Slider extends React.Component {

  state = {
    currentItem: 0,
    slides: []
  }

  componentDidMount() {

    const slides = [...document.querySelectorAll('.siema .card')];

    this.setState({slides: slides})

    let mySiema;

    const siemaMount = () => {
      if (!mySiema) {
        mySiema = new Siema({
          loop: true,
          threshold: 20,
          onChange: () => {
            this.setState({currentItem: mySiema.currentSlide})
          }
        });
      } else {
        if (mySiema) {
          mySiema.destroy();
        }
      }
    };

    siemaMount();
  }

  render() {
    return (<div className='slider'>
      <div className='siema'>
        {this.props.children}
      </div>
      <Dots items={this.state.slides} numberActiveItem={this.state.currentItem}/>
    </div>);
  }
};

export default Slider;
