class AjaxHandler {
  constructor(options) {
    this.options = options;
    this.form = this.options.form;
    this.loader = this.form ? this.form.querySelector('.loader') : null;
    this.btn = this.form ? this.form.querySelector('button[type="submit"]') : null;
  }

  onResponseSuccess(callback) {
    if (callback) {
      callback();
    }
  }

  onResponseFalse(callback) {
    if (callback) {
      callback();
    }
  }

  onAjaxHandlerProcess(callback) {
    if (callback) {
      callback();
    }
  }

  send(data, callbackSuccess, callbackFalse, callbackProcess) {
    // Event to modify data before ajax sending
    let beforeSubmitEvent;
    if (this.form) {
      beforeSubmitEvent = new CustomEvent('ajax:beforeSubmit', {
        'detail': {
          formData: data,
        }
      });
      this.form.dispatchEvent(beforeSubmitEvent);
    }

    const xhr = new XMLHttpRequest();
    xhr.open('POST', this.options.url, true);
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    xhr.send(beforeSubmitEvent ? beforeSubmitEvent.detail.formData : data);
    this._disableBtn();
    this._showLoader();

    xhr.onreadystatechange = () => {
      if (xhr.readyState === xhr.DONE && xhr.status === 200) {
        this._enableBtn();
        this._hideLoader();

        const response = JSON.parse(xhr.responseText);
        this.response = response;

        if (response.status === 'error') {
          this.onResponseFalse(callbackFalse);
        } else {
          this.onResponseSuccess(callbackSuccess);
        }
      }
    };
  }

  _disableBtn() {
    if (this.btn) {
      this.btn.setAttribute('disabled', 'disabled');
    }
  }

  _enableBtn() {
    if (this.btn) {
      this.btn.removeAttribute('disabled');
    }
  }

  _hideLoader() {
    if (this.loader) {
      this.loader.style.display = 'none';
    }
  }

  _showLoader() {
    if (this.loader) {
      this.loader.style.display = 'block';
    }
  }
}

export default AjaxHandler;
