import React from 'react';
import './col.scss';

const Col = (props) => (
  <div className={`col col-${props.countCol} ${props.className ? props.className : ''}`}>
    {props.children}
  </div>
);

export default Col;
