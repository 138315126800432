import React, {useEffect} from 'react';
import './modal.scss';
import {useDispatch, useSelector} from 'react-redux';
import {toggleModal} from '../../state/createStore';
import Form from '../form';
import Row from '../row';
import Col from '../col';
import Link from '../link';

const Modal = (props) => {


  const isModalShow = useSelector( (state) => {
    return state.isModalShow
  });

  const modalSize = useSelector( (state) => {
    return state.modalSize
  });

  const modalContent = useSelector( (state) => {
    return state.modalContent
  });

  const modalTitle = useSelector( (state) => {
    return state.modalTitle
  });

  const modalType = useSelector( (state) => {
    return state.modalType
  });

  const dispatch = useDispatch();

  useEffect(() => {
    const body = document.body;
    const header = document.querySelector('.header');
    const scrollBarWidth = window.innerWidth - document.documentElement.clientWidth;

    if (isModalShow === true) {
      body.style.overflow = 'hidden';
      body.style.paddingRight = scrollBarWidth + 'px';
      header.style.overflow = 'hidden';
      header.style.paddingRight = scrollBarWidth + 'px';
    } else {
      body.removeAttribute('style');
      header.removeAttribute('style');
    }
  }, [isModalShow]);

  let modalInnerContent = (modalType === 'empty')
    ?
    <div dangerouslySetInnerHTML={{__html: modalContent.cardModalContent}}/>
    :
    ((modalSize === 'small')
      ?
      <Form title={modalTitle} className='form--modal'/>
      : (<>
        <div className="modal__icon">
          {modalContent.cardModalIcon && <img src={modalContent.cardModalIcon} alt=""/>}
        </div>
        <Row>
          <Col countCol='2'>
            <div className="modal__text-wrapper">
              <div className="modal__icon modal__icon--inner">
                {modalContent.cardModalIcon && <img src={modalContent.cardModalIcon} alt=""/>}
              </div>
              <div className="modal__title">
                <h1>{modalContent.cardModalTitle}</h1>
                <Link href={modalContent.cardModalLink} text={modalContent.cardModalTitle}
                      className='link--def link--with-icon' icon={props.linkSvg} target="_blank"/>
              </div>
              <div className="modal__text" dangerouslySetInnerHTML={{__html: modalContent.cardModalContent}}/>
            </div>
          </Col>
          <Col countCol='2'>
            <Form title='' className='form--modal'/>
          </Col>
        </Row>
      </>));

  return (<div
    className={`modal ${isModalShow ? 'modal--show' : ''} ${modalSize === 'small' ? 'modal--small' : 'modal--big'}`}
    onClick={(e) => {
      if (e.target.classList.contains('modal')) {
        dispatch(toggleModal(false))
      }
    }}>
    <div className="modal__inner">
      <button className="close-btn" onClick={(e) => {
        if (e.target.className === 'close-btn') {
          dispatch(toggleModal(false))
        }
      }}>
        <span className="close-btn__flex" dangerouslySetInnerHTML={{__html: props.iconClose}}></span>
      </button>
      <div className="modal__content">
        {modalInnerContent}
      </div>
    </div>
  </div>);
};

export default Modal;

Modal.defaultProps = {
  iconClose: '<svg xmlns="http://www.w3.org/2000/svg" id="icon-close" viewBox="0 0 21.92 21.92">\n' +
    '    <g id="prefix__Group_997" data-name="Group 997" transform="translate(-1650 -63)">\n' +
    '        <path id="prefix__Rectangle_3" d="M0 0H29V2H0z" class="prefix__cls-1" data-name="Rectangle 3" transform="rotate(45 749.66 2024.933)"/>\n' +
    '        <path id="prefix__Rectangle_3-2" d="M0 0H2V2H0z" class="prefix__cls-1" data-name="Rectangle 3" transform="translate(1660 73)"/>\n' +
    '        <path id="prefix__Rectangle_3-3" d="M0 0H29V2H0z" class="prefix__cls-1" data-name="Rectangle 3" transform="rotate(-45 925.8 -1949.973)"/>\n' +
    '    </g>\n' +
    '</svg>'
};
