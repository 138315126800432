import React from 'react';
import './menu-top.scss';
import Link from '../link';

const MenuTop = ({menuItems}) => {
  const menuList = menuItems.map(menuItem => (
    <li key={menuItem.id} className="menu-top__item">
      <Link smooth={true} href={menuItem.link} className={'link--white'} text={menuItem.text}/>
    </li>
  ));

  return (
    <nav className="menu-top">
      <ul className="menu-top__items">
        {menuList}
      </ul>
    </nav>
  );
};

MenuTop.defaultProps = {
  menuItems: [
    {
      id: 0,
      link: '#portfolio',
      text: 'Portfolio',
    }
    , {
      id: 1,
      link: '#strategy',
      text: 'Strategy',
    },
    {
      id: 2,
      link: '#team',
      text: 'Team',
    },
    {
      id: 3,
      link: '#for-whom',
      text: 'For whom',
    },
    {
      id: 4,
      link: '#contact-us',
      text: 'Contact Us',
    }],
};

export default MenuTop;
