import React from 'react';
import './strategy.scss';
import {useStaticQuery} from "gatsby";

import Row from '../../components/row';
import Col from '../../components/col';
import Title from '../../components/title';
import AdvantagesItem from '../../components/advantages-item';
import Section from '../../components/section';

const Strategy = () => {
  const data = useStaticQuery(graphql`
      {
        wpPage(isFrontPage: {eq: true}) {
          acfMain {
            strategy {
              titleAbove
              title
              description
              columns {
                description
                icon {
                  localFile {
                    publicURL
                  }
                }
              }
            }
          }
        }
      }
    `).wpPage.acfMain.strategy;

  const advantagesList = data.columns.map((item, index) =>
    <Col countCol='4' key={index}>
      <AdvantagesItem icon={item.icon.localFile.publicURL} text={item.description}/>
    </Col>);

  return (<Section id="strategy" className='section--title section--strategy'>
      <div className="container">
        <Row className='row--m-sm'>
          <Col countCol='2' className='col--title'>
            <Title title={data.title} subTitle={data.titleAbove}/>
          </Col>
          <Col countCol='2' className='col--title'>
            <p>{data.description}</p>
          </Col>
        </Row>
        <Row>
          {advantagesList}
        </Row>
      </div>
    </Section>
  );
};

export default Strategy;
