import React from 'react';
import {useStaticQuery} from "gatsby";
import './contacts.scss';

import Link from '../link';

const Contacts = ({data}) => {
  data = {
    ...data,
    ...useStaticQuery(graphql`
      {
        wp {
          themeSettings {
            contacts {
              address
              email
              map
              phone {
                cropped
                default
              }
            }
          }
        }
      }
    `).wp.themeSettings.contacts
  };

  return (
    <div className="contacts">
      <div className="contacts__wrapper">
        <h2 className="subtitle">{data.title}</h2>
        <div className="contacts__links">
          <Link className='link--contacts' href={`mailto:${data.email}`} text={data.email} />
          <Link className='link--contacts' href={`tel:${data.phone.cropped}`} text={data.phone.default} />
        </div>
        <span className="contacts__address" dangerouslySetInnerHTML={{__html: data.address}} />
      </div>
      <div className="contacts__map" dangerouslySetInnerHTML={{__html: data.map}} />
    </div>
  );
};

Contacts.defaultProps = {
  data: {
    title: 'Contacts'
  }
};

export default Contacts;
