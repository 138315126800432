import React from 'react';
import './logo.scss';

const Logo = (props) => (
  <div className="logo" dangerouslySetInnerHTML={{__html: props.svg}}>
  </div>
);

Logo.defaultProps = {
  svg: `<svg xmlns="http://www.w3.org/2000/svg" width="71.43" height="22.2" viewBox="0 0 71.43 22.2"><path d="M-.062-.016,11.953,0V5.146L-.062,5.13Z" transform="translate(52.084 9.647) rotate(90)" fill="#28d483"/><path d="M1.95,0H7.5V-21H1.95ZM5.76-15.9h10.6V0h5.55V-15.9h10.6V-21H5.76Zm24.11,5.4c0,6.3,4.8,11.1,11.7,11.1a10.857,10.857,0,0,0,8.82-4.05A13.279,13.279,0,0,0,52.78-7.5a39.383,39.383,0,0,0,1.262-4.484L43.97-11.7v4.2h2.85A4.684,4.684,0,0,1,45.77-6a5.831,5.831,0,0,1-4.2,1.5,5.631,5.631,0,0,1-5.85-6,5.631,5.631,0,0,1,5.85-6,5.973,5.973,0,0,1,4.14,1.44,4.9,4.9,0,0,1,1.11,1.41h5.7a11.307,11.307,0,0,0-2.04-3.96,10.927,10.927,0,0,0-8.91-3.99C34.67-21.6,29.87-16.8,29.87-10.5ZM52.23-21l5.4,21H67.98l5.4-21h-5.7L63.78-5.1H61.83L57.93-21Z" transform="translate(-1.95 21.6)" /><path d="M-.062-.016,12.049,0,7.571,5.13H-.062Z" transform="translate(40.05 9.6)"/></svg>`,
};

export default Logo;
