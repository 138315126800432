import React from 'react';
import './socials.scss';

const Socials = (props) => {
  const socialsListItems = props.socialsList.map(socialsItem =>
    <a key={socialsItem.id} href={socialsItem.href} className="socials__link"
       dangerouslySetInnerHTML={{__html: socialsItem.svg}}/>);

  return (
    <div className={`socials ${props.className}`}>
      {socialsListItems}
    </div>
  );
};

Socials.defaultProps = {
  socialsList: [
    {
      id: 0,
      href: '#',
      svg: '<svg xmlns="http://www.w3.org/2000/svg" id="prefix__Component_13_1" width="52" height="52" data-name="Component 13 – 1" viewBox="0 0 52 52">\n' +
        '    <circle id="prefix__Ellipse_46" cx="26" cy="26" r="26" fill="#28d483" data-name="Ellipse 46" opacity="0.01"/>\n' +
        '    <path id="prefix__Path_25483" d="M305.716 17.867H309v-8.12h2.716l.4-3.169h-3.14V4.57c0-.905.255-1.528 1.556-1.528h1.669V.213C311.912.185 310.922.1 309.762.1a3.821 3.821 0 0 0-4.074 4.187v2.292H303v3.168h2.744v8.12z" data-name="Path 25483" transform="translate(-282.005 16.9)"/>\n' +
        '</svg>'
    },
    {
      id: 1,
      href: '#',
      svg: '<svg xmlns="http://www.w3.org/2000/svg" id="prefix__Component_14_1" width="52" height="52" data-name="Component 14 – 1" viewBox="0 0 52 52">\n' +
        '    <circle id="prefix__Ellipse_47" cx="26" cy="26" r="26" fill="#28d483" data-name="Ellipse 47" opacity="0.01"/>\n' +
        '    <path id="prefix__Union_3" d="M12.6 15.89v-5.14c0-1.254-.031-2.821-1.724-2.821-1.724 0-1.976 1.347-1.976 2.727v5.234H5.61V5.3h3.166v1.439h.031a3.422 3.422 0 0 1 3.1-1.724c3.354 0 3.949 2.194 3.981 5.078v5.8zM.251 15.858V5.265h3.291v10.593zM0 1.912A1.9 1.9 0 0 1 1.913 0a1.9 1.9 0 0 1 1.911 1.912 1.9 1.9 0 0 1-1.911 1.912A1.9 1.9 0 0 1 0 1.912z" data-name="Union 3" transform="translate(20 16.532)"/>\n' +
        '</svg>\n'
    }
  ]
};

export default Socials;
