import React, {useEffect} from 'react';
import {StaticQuery} from "gatsby";
import './portfolio.scss';

import Row from '../../components/row';
import Card from '../../components/card';
import Slider from '../../components/slider'
import Section from '../../components/section';


class Portfolio extends React.Component {

  state = {
    isSliderInit: false
  }

  setSliderState = () => {
    if (window.innerWidth > 768) {
      if (this.state.isSliderInit) {
        this.setState({isSliderInit: false})
      }
    } else {
      if (!this.state.isSliderInit) {
        this.setState({isSliderInit: true})
      }
    }
  }

  componentDidMount() {
    this.setSliderState();
    window.addEventListener('resize', this.setSliderState);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setSliderState);
  }

  render() {
    const cardsProjects = <StaticQuery
      query={graphql`
      {
        allWpPortfolio {
          nodes {
            acfPortfolio {
              link
              icon {
                localFile {
                  publicURL
                }
              }
            }
            id
            content
            excerpt
            title
            featuredImage {
              node {
                localFile {
                  publicURL
                }
              }
            }
          }
        }
      }
    `}
      render={data => {
        return data.allWpPortfolio.nodes.map(project => {
          const image = project.featuredImage && project.featuredImage.node.localFile.publicURL;
          const icon = project.acfPortfolio.icon && project.acfPortfolio.icon.localFile.publicURL;
          const content = {
            content: project.content,
            link: project.acfPortfolio.link,
            svg: image
          }

          return (
            <Card key={project.id}
                  cardSvg={icon}
                  title={project.title}
                  text={project.excerpt} modalContent={content} mod={this.state.isSliderInit ? 'slider' : ''}/>
          )
        })
      }
      }
    />;

    const content = this.state.isSliderInit
      ?
      <Slider>
        {cardsProjects}
      </Slider>
      :
      <Row>
        {cardsProjects}
      </Row>;

    return (
      <Section id="portfolio" className="section--bg section--gray">
        <div className="container">
          <h2 className="visually-hidden">{this.props.title}</h2>
          {content}
        </div>
      </Section>
    );
  }
}

Portfolio.defaultProps = {
  title: 'Portfolio',
};

export default Portfolio;
