import React from 'react';
import Img from 'gatsby-image';
import './person.scss';

const Person = props =>
  <div className='person'>
    <div className='person__img'>
      <Img
        fluid={props.person.featuredImage.node.localFile.childImageSharp.fluid}
        alt={props.person.title}
      />
    </div>
    <div className="person__wrapper">
      <span className="person__name">{props.person.title}</span>
      {props.person.content &&
      <span className="person__position">
        {/* Strip html tags from content */}
        {props.person.content.replace(/(<([^>]+)>)/gi, "")}
      </span>
      }
    </div>
  </div>;

export default Person;
