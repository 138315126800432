import React from 'react';
import './form-control.scss';

class FormControl extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isFilled: false,
      isFocused: false
    }
  }

  onInputFocus = () => {
    this.setState({isFocused: true});
  }

  onInputBlur = (e) => {
    this.setState({isFocused: false});
    if (e.target.value !== '') {
      this.setState({isFilled: true});
    } else {
      this.setState({isFilled: false});
    }
  }

  render() {
    const {id, name, type, label, required} = this.props.data;
    let className = 'form-control';
    className += this.state.isFilled ? ' filled' : '';
    className += this.state.isFocused ? ' focused' : '';
    className += (this.props.error && (type !== 'textarea')) ? ' error' : '';

    let errorMsg = this.props.error ? <span className='error-msg'>{this.props.error}</span> : '';

    const control = (type === 'textarea') ?
      <textarea onFocus={this.onInputFocus} onBlur={this.onInputBlur} name={name} id={id}
                className='input' value={this.props.value} onChange={this.props.onChange}/> :
      <><input onFocus={this.onInputFocus} onBlur={this.onInputBlur} type={type} name={name}
               className='input' id={id} value={this.props.value} onChange={this.props.onChange}/>{errorMsg}</>;

    return (
      <div className={className}>
        <label htmlFor={id} className="label">{label}</label>
        {control}
      </div>
    );
  }
}

export default FormControl;
