import React from 'react';
import './footer.scss';

import Socials from '../socials';
import LinkPolicy from '../link-policy';

const Footer = () => {

  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__wrapper">
          <span>© 2020 ITGV. all rights reserved</span>
          <div className="footer__policy">
            <LinkPolicy href='#' className='link--gray' text={'Privacy policy'}/>
          </div>
          <div className="footer__socials">
            <Socials className='socials--gray socials--footer'/>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer;
