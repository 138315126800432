import React from 'react';
import './card.scss';
import Col from '../col';
import { useDispatch } from 'react-redux'
import {toggleModal, toggleModalContent, toggleModalSize, toggleModalType, toggleModalTitle} from '../../state/createStore';

const Card = (props) => {
    const {cardSvg, title, text, modalContent, mod} = props;
    const dispatch = useDispatch();

    const contentForModal = {
      cardModalIcon: modalContent.svg,
      cardModalTitle: title,
      cardModalContent: modalContent.content,
      cardModalLink: modalContent.link
    }

  return (mod === 'slider' ? <div className={'slider__item'}>{ getCard(cardSvg, title, text, contentForModal, dispatch)} </div>: <Col countCol='3'>
      {getCard(cardSvg, title, text, contentForModal, dispatch)}
    </Col>);
}

const onCardClick = (dispatch, modalContent) => {
  dispatch(toggleModal(true));
  dispatch(toggleModalType('with-form'));
  dispatch(toggleModalSize('big'));
  dispatch(toggleModalContent(modalContent));
  dispatch(toggleModalTitle(''));
}

const getCard = (svg, title, text, contentForModal, dispatch) => <div className='card'>
  <div onClick={() => onCardClick(dispatch, contentForModal)} className={`card__link`}>
    <div className="card__header">
      {svg && <img src={svg} alt={title}/>}
    </div>
    <div className="card__content">
      <h3 className="card__title">{title}</h3>
      <div className="card__text" dangerouslySetInnerHTML={{__html: text}}></div>
    </div>
    <div className="card__footer">
      <span className="card__footer-text">Details</span>
      <img src="/images/arrow.svg" alt=""/>
    </div>
  </div>
</div>;

export default Card;
