import React from 'react';
import {useStaticQuery} from "gatsby";

import Row from '../../components/row';
import Col from '../../components/col';
import Title from '../../components/title';
import Person from '../../components/person';
import Section from '../../components/section';

const OurTeam = () => {
  const data = useStaticQuery(graphql`
    {
      wpPage(isFrontPage: {eq: true}) {
        acfMain {
          team {
            title
            titleAbove
          }
        }
      }
      allWpTeam {
        nodes {
          id
          content
          title
          menuOrder
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  fluid {
                     srcSet
                     srcSetWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const team = data.allWpTeam.nodes.sort((a, b) => a.menuOrder - b.menuOrder);
  const personsList = team.map(item =>
    <Col countCol='4' key={item.id}>
      <Person person={item}/>
    </Col>);

  return (<Section id="team" className='section--title section--bg section--gray section--our-team'>
      <div className="container">
        <Row className='row--title'>
          <Col countCol='2'>
            <Title title={data.wpPage.acfMain.team.title} subTitle={data.wpPage.acfMain.team.titleAbove}/>
          </Col>
        </Row>
        <Row>
          {personsList}
        </Row>
      </div>
    </Section>
  );
};

export default OurTeam;
