import React from 'react';
import './advantages-item.scss';

const AdvantagesItem = (props) => <div className='advantages-item'>
  <div className='advantages-item__icon'>
    <img src={props.icon} alt=""/>
  </div>
  <p>{props.text}</p>
</div>;

export default AdvantagesItem;

