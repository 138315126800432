import React from 'react';
import './form.scss';
import AjaxHandler from "../../modules/ajax-handler";
import {useDispatch, connect} from "react-redux";

import FormControl from '../form-control';
import Link from '../link';
import LinkPolicy from "../link-policy";
import {toggleModal, toggleModalContent, toggleModalType, toggleModalTitle, toggleModalSize} from "../../state/createStore";

class Form extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      values: {},
      errors: {}
    };

    this.state.values = props.fields.reduce((carry, field) => {
      carry[field.name] = "";
      return carry;
    }, {});

    this.formTitle = props.title ? <h2 className='subtitle'>{props.title}</h2> : '';
    this.ajaxHandler = new AjaxHandler({
      url: process.env.MAIL_URL
    });

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleSubmit(e) {
    e.preventDefault();
    const data = new FormData;
    data.append('action', 'form_ajax');
    Object.keys(this.state.values).map(name => data.append(name, this.state.values[name]));

    this.ajaxHandler.send(data, () => {
      const response = this.ajaxHandler.response;
      this.setState({errors: response.errors});

      if (!Object.keys(response.errors).length) {
        const dispatch = this.props.dispatch;

        if (!this.props.isModalShow)
          dispatch(toggleModal(true));

        dispatch(toggleModalSize('small'));
        dispatch(toggleModalType('empty'));
        dispatch(toggleModalContent({
          cardModalTitle: '',
          cardModalIcon: '',
          cardModalContent: response.message,
          cardModalLink: '',
        }));

        const values = {...this.state.values};
        Object.keys(values).map(name => values[name] = '');
        this.setState({values});
      }
    });
  }

  handleInputChange(e) {
    this.setState({
        values: {...this.state.values, [e.target.name]: e.target.value}
      }
    );

    let errors = {...this.state.errors};
    errors[e.target.name] = false;
    this.setState({errors})
  }

  render() {

    return (
      <form className={`form ${this.props.className ? this.props.className : ''}`} onSubmit={this.handleSubmit}>
        <div className='form__header'>
          {this.props.title && <h2 className='subtitle'>{this.props.title}</h2>}
          <p>{this.props.text}</p>
        </div>
        <div className='form__body'>
          {this.props.fields.map(field =>
            <FormControl key={field.id} data={field} value={this.state.values[field.name]}
                         onChange={this.handleInputChange} error={this.state.errors[field.name]}/>
          )}
        </div>
        <div className='form__footer'>
          <button type='submit' className='btn btn--fill'>Send</button>
          <p className='form__policy'>
            {this.props.policy.text}
            <span className='nobr'> "<LinkPolicy href='#' text={'Privacy policy'}/>"
          </span>
          </p>
        </div>
      </form>
    );
  }
}

Form.defaultProps = {
  title: 'EMAIL US',
  text: 'Contact us for existing investor opportunities\n' +
    'by filling out the form below.',
  fields: [
    {
      id: 'input-0',
      name: 'name',
      type: 'text',
      label: 'Name',
      required: true
    },
    {
      id: 'input-1',
      name: 'phone',
      type: 'tel',
      label: 'Phone',
      required: true
    },
    {
      id: 'input-2',
      name: 'email',
      type: 'email',
      label: 'Email',
      required: true
    },
    {
      id: 'input-3',
      name: 'message',
      type: 'textarea',
      label: 'Message',
      required: false
    }
  ],
  policy: {
    text: 'By clicking on the button, you agree to the terms',
    hrefText: 'privacy policy',
    href: '#'
  }
};

const mapStateToProps = state => {
  return {
    isModalShow: state.isModalShow
  }
};

export default connect(mapStateToProps)(Form);
