import React from 'react';
import './for-whom-item.scss';
import Button from '../button';

const ForWhomItem = props =>
  <div className="for-whom-item">
    <div className="for-whom-item__img">
      {props.client.acfPartners.icon.localFile.publicURL && <img src={props.client.acfPartners.icon.localFile.publicURL} alt={props.client.title}/>}
    </div>
    <h2 className="for-whom-item__title">{props.client.title}</h2>
    <div className="for-whom-item__text" dangerouslySetInnerHTML={{__html: props.client.content}}></div>
    <div className="for-whom-item__footer">
      <Button className='btn--transparent' dataToggle='#' dataTarget='#' text='Learn more'/>
    </div>
  </div>;

export default ForWhomItem;

