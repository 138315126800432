import React from 'react';
import './button.scss';
import { useDispatch } from 'react-redux'
import {toggleModal, toggleModalContent, toggleModalSize, toggleModalType, toggleModalTitle} from '../../state/createStore';


const Button = (props) => {
  const dispatch = useDispatch();

  return (<button className={`btn ${props.className}`} onClick={() => onBtnClick(dispatch)}>
    {props.text}
  </button>);
};

const onBtnClick = (dispatch) => {
  dispatch(toggleModal(true));
  dispatch(toggleModalType('with-form'));
  dispatch(toggleModalSize('small'));
  dispatch(toggleModalContent(null));
  dispatch(toggleModalTitle('Contact us'));
}

export default Button;
