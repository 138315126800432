import React from 'react';
import './section.scss';

const Section = (props) => (
  <section id={props.id} className={`section ${props.className ? props.className : ''}`}>
    {props.children}
  </section>
);

export default Section;
