import React from 'react';
import './contacts-section.scss';
import Contacts from '../../components/contacts';
import Form from '../../components/form';
import Section from '../../components/section';

const ContactsSection = () =>
  <Section id="contact-us">
    <div className="container">
      <div className="contacts-section">
        <div className="contacts-section__wrapper">
          <Contacts/>
        </div>
        <div className="contacts-section__form">
          <Form className='form--def' />
        </div>
      </div>
    </div>
  </Section>;

ContactsSection.defaultProps = {};

export default ContactsSection;
