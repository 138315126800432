import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import Img from 'gatsby-image';
import Button from '../button';
import './title-block.scss';

function TitleBlock({data}) {
  const query = useStaticQuery(graphql`
    {
      file(relativePath: {eq: "first-bg.png"}) {
        childImageSharp {
          fluid {
            srcSet
            srcSetWebp
          }
        }
      }
      wpPage(isFrontPage: {eq: true}) {
        acfMain {
          title
          subtitle
          mainImage {
            localFile {
              childImageSharp {
                fluid {
                  srcSet
                  srcSetWebp
                }
              }
            }
          }
        }
      }
    }
  `);
  const queriedData = query.wpPage.acfMain;
  return (
    <div className="title-block">
      <div className="title-block__bg">
        <Img
          fluid={query.file.childImageSharp.fluid}
          alt=""
        />
      </div>
      <div className="title-block__dots-bg" />
      <div className="title-block__img">
        <Img
          fluid={queriedData.mainImage.localFile.childImageSharp.fluid}
          alt=""
        />
      </div>
      <div className="container">
        <div className="title-block__wrapper">
          <div className="title-block__text-wrapper">
            <h1>{queriedData.title}</h1>
            <p>{queriedData.subtitle}</p>
            <Button className={data.btnClass} dataToggle={data.btnToggle} dataTarget={data.btnTarget}
                    text={data.btnText}/>
          </div>
        </div>
      </div>
    </div>
  )
}

TitleBlock.defaultProps = {
  data: {
    bg: '/images/first-bg.png',
    btnText: 'Learn more',
    btnToggle: '#',
    btnTarget: '#',
    btnClass: 'btn--fill'
  },
};

export default TitleBlock;
